export enum WOOD_CODE {
    OK = 0,
    STOCK_NOT_FOUND = 1,
    INSERT_TRANSACTION_FAILED = 2,
    UPDATE_STOCK_AT_TRANSACTION_FAILED = 3,
    NOTHING_CHANGE = 4,
    INSERT_STOCK_FAILED = 5,
    REPEAT_STOCK = 6,
    UPDATE_TRANSACTION_COMMODITY_FAILED = 7,
    SAVE_SORT_SETTING_FAILED = 8,
    // 部分退货货物全满
    PARTIAL_RETURN_GOODS_ARE_FULLY_FILLED = 9
}